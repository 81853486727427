import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './services/login.service';
import {ConfigManagerService} from '@xpo-ltl/config-manager';
import {Observable, forkJoin} from 'rxjs';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';

@Component({
  selector: 'xpo-login',
  template: `<form [formGroup]="userForm" novalidate>
  <mat-dialog-content>
    <div class="login-dialog__logo-container">
      <svg class="login-dialog__logo-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 188.8 27.6" style="enable-background:new 0 0 188.8 27.6;" xml:space="preserve">
      <g>
      <polygon class="xpo-logo-logistics" points="81.1,22.2 64.9,22.2 64.9,0.6 71.8,0.6 71.8,16.5 81.1,16.5     "/>
      <g>
          <path class="xpo-logo-logistics" d="M98.1,14.3c0,4.9-3.6,8.3-8.6,8.3c-5.1,0-8.6-3.4-8.6-8.3s3.5-8.3,8.7-8.3C94.6,6.1,98.1,9.4,98.1,14.3
               M86.9,14.4c0,2.6,0.8,4,2.5,4c1.8,0,2.8-1.3,2.8-4.1c0-2.6-1-4-2.8-4C87.7,10.4,86.9,11.8,86.9,14.4"/>
          <path class="xpo-logo-logistics" d="M114.9,20.1c0,2-0.3,3.1-1,4.2c-1.3,2.1-3.5,3-7.2,3c-5.9,0-7.5-1.3-8-5.3h5.6c0.3,1.3,0.7,2,2.4,2
              c1.8,0,2.4-0.8,2.4-3v-1.7c-1.2,1.4-2.4,1.9-4.3,1.9c-4.2,0-6.7-2.8-6.7-7.4c0-4.6,2.6-8,6.7-8c2.1,0,3.2,0.6,4.3,2.3V6.5h5.9
              V20.1z M103.9,14c0,2.2,0.8,3.3,2.6,3.3c1.7,0,2.6-1.1,2.6-3.1c0-2.1-0.9-3.8-2.6-3.8C104.9,10.5,103.9,12.1,103.9,14"/>
          <rect x="116.2" y="6.5" class="xpo-logo-logistics" width="6.1" height="15.6"/>
          <path class="xpo-logo-logistics" d="M148.5,11h-4.3v4.8c0.1,1.9,0.3,2.1,2.3,2.1h1.8h0.2v4.2c-1.5,0.1-3.3,0.2-4.3,0.2c-3,0-4.7-0.6-5.6-2.2
              c-0.5-0.8-0.6-1.4-0.6-3.2l0-10.5l6.2-5.9v5.9h4.3V11z"/>
          <rect x="149.9" y="6.5" class="xpo-logo-logistics" width="6.1" height="15.6"/>
          <path class="xpo-logo-logistics" d="M164.6,22.5c-5.1,0-8.6-3.2-8.6-8.1c0-5,3.4-8.3,8.6-8.3c4.5,0,7.7,2.6,8,6.3h-5.8c-0.2-1.3-1-2.1-2.2-2.1
              c-1.7,0-2.6,1.5-2.6,4.2c0,2.4,0.9,3.7,2.6,3.7c0.9,0,2.1-0.2,2.3-1.8h5.8C172.2,20.6,169.2,22.5,164.6,22.5"/>
          <path class="xpo-logo-logistics" d="M182.6,11c-0.2-1.1-0.7-1.4-2-1.4c-1.1,0-1.6,0.4-1.6,1c0,0.7,0.3,0.9,3.4,1.5c3.4,0.6,5,1.5,5.7,2.9
              c0.3,0.6,0.5,1.4,0.5,2.1c0,3.5-2.9,5.5-7.9,5.5c-5.8,0-7.6-2.3-7.9-6.1h5.7c0.1,1.3,0.2,2.4,2.3,2.4c1.1,0,1.9-0.5,1.9-1.3
              c0-0.7-0.1-1-3-1.6c-2.8-0.6-3.6-0.8-4.6-1.4c-1.3-0.7-2-2-2-3.6c0-3.2,2.6-5,7.4-5c5,0,7.4,1.5,7.7,5H182.6z"/>
          <path class="xpo-logo-logistics" d="M132.2,11c-0.2-1-0.7-1.4-2-1.4c-1.1,0-1.6,0.4-1.6,1c0,0.7,0.3,0.9,3.4,1.5c3.4,0.6,5,1.5,5.7,2.9
              c0.3,0.6,0.5,1.4,0.5,2.1c0,3.5-2.9,5.5-7.9,5.5c-5.8,0-7.6-2.4-7.9-6.1h5.7c0.1,1.3,0.2,2.4,2.3,2.4c1.1,0,1.9-0.5,1.9-1.3
              c0-0.7-0.1-1-3-1.6c-2.8-0.6-3.6-0.8-4.6-1.4c-1.3-0.7-2-2-2-3.6c0-3.2,2.6-5,7.4-5c5,0,7.4,1.5,7.7,5H132.2z"/>
          <path class="xpo-logo-xpo" d="M15.4,10.7l7.8,11.4h-8L13,18.7c-0.7-1.2-1.1-1.9-1.6-3.1c-0.4,0.9-1,2.1-1.7,3.2l-1.8,3.4H0l7.8-11.4L0,0.6
              h7.7c0,0,3.5,4.9,4,6c0.4-0.8,3.6-6,3.6-6h7.9L15.4,10.7z"/>
          <path class="xpo-logo-xpo" d="M32.2,0.6c3.5,0,5.2,0.3,6.6,1.3c2,1.3,3.1,3.6,3.1,6.3c0,2.1-0.7,3.9-2.1,5.3c-1.4,1.4-3.2,2-6,2H30v6.6
              h-6.8V0.6H32.2z M30,10.4h2.6c1.9,0,2.7-0.7,2.7-2.2c0-1.6-0.9-2.3-2.9-2.3H30V10.4z"/>
          <path class="xpo-logo-xpo" d="M63.7,11.4c0,6.8-4.4,11.3-10.9,11.3c-6.6,0-11-4.5-11-11.3C41.8,4.5,46.2,0,52.9,0
              C59.4,0,63.7,4.6,63.7,11.4 M48.7,11.3c0,3.9,1.4,6.1,4.1,6.1c2.7,0,4-2.2,4-6.3c0-3.6-1.4-5.8-4-5.8C50.2,5.3,48.7,7.6,48.7,11.3
              "/>
      </g>
  </g>
  </svg>
    </div>
    <div class="fields">

      <mat-form-field>
      <input
        matInput
        type="text"
        name="username"
        id="username"
        placeholder="Your Windows NTID"
        formControlName="username"
        autocomplete="on"
        required />
      </mat-form-field>

      <div class="spacer"></div>

      <mat-form-field>
      <input
        matInput
        type="password"
        name="password"
        id="password"
        placeholder="Password"
        formControlName="password"
        autocomplete="off"
        autocomplete="password"
        required/>
      </mat-form-field>

    </div>
  </mat-dialog-content>
  <mat-error class="error-message" *ngIf="errorMessage">{{ errorMessage }}</mat-error>
  <p></p>
  <mat-dialog-actions class="actions">
    <button mat-raised-button color="primary" (click)="onSubmit()">Sign In</button>
  </mat-dialog-actions>
</form>
`,
  styles: [`.xpo-logo-logistics{fill:#1c1e1c}.xpo-logo-xpo{fill:#c00}.login-dialog__logo-svg{width:250px}.login-dialog__logo-container{display:flex;flex-direction:row;align-items:center;justify-content:center;margin-bottom:16px}.login-dialog__logo{height:100%;display:flex;max-width:250px;margin:20px auto}.fields{max-width:200px;margin-left:auto;margin-right:auto;display:flex;flex-direction:column}.error-message{color:#d50000;font-weight:400;font-size:12px;display:flex;flex-direction:column}.actions{justify-content:center;flex-direction:row;margin-bottom:20px}.spacer{float:right;min-height:20px;min-width:1px}`]
})
export class LoginComponent {
  userForm: FormGroup;
  errorMessage = null;

  constructor(private loginService: LoginService,
              private dialogRef: MatDialogRef<LoginComponent>,
              private fb: FormBuilder,
              private configManager: ConfigManagerService) {
    this.createForm();
  }

  /** closes the dialog */
  close() {
    this.dialogRef.close('canceled');
  }

  onSubmit() {
    this.errorMessage = null;
    const userName = this.userForm.get('username').value;
    const password = this.userForm.get('password').value;
    this.loginService.loginByApplication(userName, password).subscribe(
      data => {
        this.dialogRef.close(data);
      },
      error => {
        if (error.error.error_description && error.error.error) {
          this.errorMessage = `${error.error.error_description}`;
          this.errorMessage = this.errorMessage.replace('@carbon.super', '');
        } else {
          this.errorMessage = error.error;
        }
        this.loginService.clear();
      },
    );
  }

  private createForm() {
    this.userForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
}
